import * as types from '../constants/ActionTypes'
import queryString from 'query-string'

let nextMessageId = 1;
const {bot_id} = queryString.parse(window.location.search);
const storedState = localStorage.getItem(`reduxState_${bot_id}`);
let sessionData = '';
if (storedState !== null) {
  const { message } = JSON.parse(storedState);
  
}
let nextUserId = 0

export const addMessage = (message, author,session,feedback) => ({
  type: types.ADD_MESSAGE,
  id: nextMessageId++,
  message,
  author,
  session,
  feedback
})

export const addUser = name => ({
  type: types.ADD_USER,
  id: nextUserId++,
  name
})

export const messageWillbeReceived = (feedBack) => ({
  feedBack,
  type:types.MESSAGE_WILL_BE_RECEIVED,
  id: nextMessageId++
});

export const disableUserInput = () => ({
  type: types.DISABLEUSERINPUT
});

export const enableInput = () => ({
  type: types.ENABLEUSERINPUT
});


export const disableCal = () => ({
  type: types.DISABLECAL
});

export const enableCal = () => ({
  type: types.ENABLECAL
});

export const addSelectList = (item) => ({
  type: types.ENABLEMULSEL,
  mulItem: item,
});

export const clearSelectList = () => ({
  type: types.DISABLEMULSEL
});

export const messageReceived = (message,author,extraChatsCount,dtime,feedBack,current_page,parameters,contexts) => ({
  type: types.MESSAGE_RECEIVED,
  id: nextMessageId++,
  message,
  extraChatsCount:extraChatsCount,
  dtime:dtime,
  feedBack,
  author,
  current_page,
  parameters,
  contexts
})

export const additionalMessageRecieved = (message,extraChatsCount,dtime,feedBack) =>({
  type:"ADD_EXTRA_MESSAGE",
  extra_message:message,
  dtime:dtime,
  extraChatsCount:extraChatsCount,
  feedBack
})

export function messageError(errors) {  
  return {
    type: "CHAT_VALIDATION_ERROR",
    errors
  }
}