import { combineReducers } from 'redux'
import message from './message'
import bot from './bot'
import operators from './operators'
const chat = combineReducers({
  message,
  bot,
  operators
})

export default chat