
export const parseMessage = (message) => {
  const n = message.indexOf('<');
  const l = message.lastIndexOf('>');
  const extract = message.substr(n, l);
  let finalMessage = '';
  let appendHTML = '';
  let disableInput = false;
  let enableCal = false;
  let dtFound = message.match(/::datepicker::/g);
  if (dtFound) {
    enableCal = true;
  }
  if (/<[a-z][\s\S]*>/i.test(extract)) {
    let etData = `  ${extract} 
                    `;
    let dInputIndex = etData.indexOf('%disable');
    if (dInputIndex > 0) {
      disableInput = true;
      //console.log('disable index is' + dInputIndex+etData)
      appendHTML = etData.substr(0,dInputIndex);
    }
    else
    	appendHTML = etData;
    const str1 = message.substr(0, n);
    let str2 = message.substr(l + 1);
    dInputIndex = str2.indexOf('%disable');
    str2 = str2.substr(0,dInputIndex);
    //if (dInputIndex > 0)  disableInput = true;
    //console.log('disable index is' + dInputIndex)
    finalMessage = str1 + str2;
  }
  return { text: finalMessage, html: appendHTML, disableInput, enableCal };
};

export const createMarkup = (html, styles = '') => {
  if (styles !== '') {
    html = html.replace(/type="button"/g, 'type="button" style="color:'+styles.color+'; background-color: '+styles.backgroundColor+'"')
    html = html.replace(/class"quick_reply"/g, '');
    html = html.replace(/<a/g, '<a style="color:'+styles.color+'; background-color: '+styles.backgroundColor+'"');
  }
  return { __html: html };
};

export const removeColons = (message) => {
  const matches = message.match(/::(.+?)0::/g);
  let start = 0;
  let end = 0;
  let extract = 0;
  let res = '';
  const returnArr = [];
  if (matches !== null) {
    for (let i = 0; i < matches.length; i += 1) {
      console.log(matches[i]);
      end = message.indexOf(matches[i]);
      if (res !== '') {
        if (`::next-${res}::` === matches[i]) {
          start = message.indexOf(`::next-${res}::`, end + 13);
        } else {
          start = message.indexOf(`::next-${res}::`);
        }
      }
      console.log(start);
      extract = message.substring(start, end);
      extract = extract.replace(`::next-${res}::`, '');
      if (res === '') {
        returnArr.push(extract);
        // dispatch(messageReceived(extract,author))
      } else if (extract !== '') {
        returnArr.push(extract); // delay(res,extract,author,dispatch)
      }
      res = matches[i].replace(/\D/g, '');
    }
    // for left over message
    if (start <= end) start = end;
    end = message.length;
    extract = message.substring(start, end);
    extract = extract.replace(`::next-${res}::`, '');
    if (extract !== '') returnArr.push(extract);// delay(res,extract,author,dispatch)
  } else {
    returnArr.push(extract);// dispatch(messageReceived(data.message, data.author))
  }
  return returnArr;
};
