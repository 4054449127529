import { takeEvery,takeLatest } from 'redux-saga/effects'
import * as types from '../constants/ActionTypes'
import {fetchBotDetails} from '../api/index'
import queryString from 'query-string'

export const handleNewMessage = function* handleNewMessage(params) {
  yield takeEvery(types.ADD_MESSAGE, (action) => {
    const agent_id = localStorage.getItem("agent_id");
    const {bot_id} = queryString.parse(window.location.search) 
    action.agent_id = agent_id
    action.bot_id = bot_id;
    const storedState = localStorage.getItem(`reduxState_${bot_id}`);
    if (storedState !== null) {
        const { message } = JSON.parse(storedState);
        if (message.chats.length > 0) {
          const { parameters, current_page, contexts } = message;
          action.parameters = parameters;
          action.current_page = current_page;
          action.contexts = contexts;
        }
    };

    setTimeout(function(){
      params.socket.send(JSON.stringify(action))
    }, 2500);
  })
  yield takeLatest("API_CALL_REQUEST", dispatchToServiceHandler);
}


const dispatchToServiceHandler=function* dispatchToServiceHandler (action){
  switch(action.subType){
    case 'BOT_CALL_REQUEST':
      yield fetchBotDetails(action);
      break;
        
    default:
      break;
  }
}

export default handleNewMessage