export const ADD_MESSAGE = 'ADD_MESSAGE'
export const MESSAGE_RECEIVED = 'MESSAGE_RECEIVED'
export const MESSAGE_WILL_BE_RECEIVED = 'MESSAGE_WILL_BE_RECEIVED'
export const DISABLEUSERINPUT = 'DISABLEUSERINPUT';
export const ENABLEUSERINPUT = 'ENABLEUSERINPUT';
export const DISABLECAL = 'DISABLECAL';
export const ENABLECAL = 'ENABLECAL';
export const DISABLEMULSEL = 'DISABLEMULSEL';
export const ENABLEMULSEL = 'ENABLEMULSEL';
export const ADD_USER = 'ADD_USER'
export const USERS_LIST = 'USERS_LIST'
export const SERVER_ADDRESS = window.location.hostname
export const API_CALL_REQUEST = "API_CALL_REQUEST";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const API_CALL_FAILURE = "API_CALL_FAILURE";