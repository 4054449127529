import * as types from '../constants/ActionTypes'

export const initCall=(subType,bot_id)=>({
    type: types.API_CALL_REQUEST,
    subType,
    bot_id
})

export const callSuccess=(subType,data)=>({
    type: types.API_CALL_SUCCESS,
    subType,
    data
})

export const callFailure=(subType,data)=>({
    type: types.API_CALL_FAILURE,
    subType,
    data
})