import { connect } from 'react-redux';
import AddMessageComponent from '../components/AddMessage';
import { addMessage, messageError, messageWillbeReceived, clearSelectList } from '../actions';
import chatValidationErrors from '../validators/chatValidationErrors';
import chatIsValid from '../validators/chatIsValid'; 

const mapDispatchToProps = dispatch => ({
  dispatch: (message, author, feedback) => {
    console.log("the feed back is"+feedback )
    if (message !== '') {
      const session = localStorage.getItem('key');
      dispatch(addMessage(message, author, session,feedback));
      dispatch(clearSelectList());
      if  (feedback)
        dispatch(messageWillbeReceived());
    } else {
      const errors = chatValidationErrors({ message });
      if (!chatIsValid(errors)) dispatch(messageError(errors));
    }
  },
});


export const AddMessage = connect(state => ({
  message: state.message,
  bot:state.bot
}), mapDispatchToProps)(AddMessageComponent)