import { connect } from 'react-redux';
import MessagesListComponent from '../components/MessagesList';
import queryString from 'query-string';
import { messageWillbeReceived, 
  addMessage, 
  disableUserInput,
  enableInput,
  enableCal,
  disableCal,
  addSelectList,
  clearSelectList,
} from '../actions/index';

const mapDispatchToProps = dispatch => ({
  dispatch: () => {
    const {bot_id} = queryString.parse(window.location.search);
    const reduxState = JSON.parse(localStorage.getItem(`reduxState_${bot_id}`));
    console.log('why is it not appearing');
    console.log(reduxState.message);
    if (reduxState.message.chats.length < 1) 
      dispatch(messageWillbeReceived());
  },
  dispatchAddSelList: (item) => {
    dispatch(addSelectList(item));
  },
  dispatchAddMessage: (text, author) => {
    const session = localStorage.getItem('key');
    dispatch(addMessage(text, author, session));
    dispatch(messageWillbeReceived());
    dispatch(clearSelectList());
  },
  dispatchDisableInput: () => {
    dispatch(disableUserInput()) ;
  },
  dispatchEnableCal: () => {
    dispatch(enableCal()) ;
  },
  dispatchDisableCal: () => {
    dispatch(disableCal()) ;
  },
});

const MessagesList = connect(state => ({
  message: state.message,
  bot: state.bot,
  operators: state.operators,
}), mapDispatchToProps)(MessagesListComponent);
export default MessagesList;
