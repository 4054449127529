import axios from "axios";
import {callSuccess,callFailure} from "../actions/ApiCalls"
import { call, put } from 'redux-saga/effects'
import * as types from '../constants/ActionTypes'
const fetchBot = (bot_id) =>{
    const storedKey = localStorage.getItem("key");
    //let url = window.location.protocol+"//"+types.SERVER_ADDRESS+":8082/bot/"+bot_id+"?session="+storedKey;
    let urlData = new URL(document.location);
    let pageData = {
        title: urlData.searchParams.get("title"),
        actUrl: urlData.searchParams.get("url"),
        referrer: urlData.searchParams.get("referrer"),
        keyword: urlData.searchParams.get("keyword"),
        gclid: urlData.searchParams.get("gclid"),
        msclkid: urlData.searchParams.get("msclkid"),
        option: urlData.searchParams.get("option"),
        trigger: urlData.searchParams.get("trigger"),
    };
    let { title, 
        actUrl, 
        referrer,
        keyword,
        gclid,
        msclkid,
        option,
        trigger,
    } = pageData;

    const storedState = localStorage.getItem(`reduxState_${bot_id}`);
    let sessionData = '';
    if (storedState !== null) {
        const { message } = JSON.parse(storedState);
        if (message.chats.length > 0) {
            const queryParameters = encodeURI(JSON.stringify(message.parameters));
            sessionData = `&parameters=${queryParameters}&current_page=${message.current_page}`;
        }
    }

    let paramData = '&title='+encodeURI(title)+'&referrer='+encodeURIComponent(referrer)+'&keyword='+encodeURI(keyword)+'&gclid='+encodeURI(gclid)+'&msclkid='+encodeURI(msclkid)+'&url='+encodeURIComponent(actUrl)+'&option='+encodeURI(option);
    paramData += '&trigger='+trigger;
    paramData += sessionData;
    let url = window.location.protocol+"//"+types.SERVER_ADDRESS+":8083/bot/"+bot_id+"?session="+storedKey+paramData;
    console.log(url)
    return axios({
        method: "get",
        url: url
    });
} 
  
export function* fetchBotDetails(action){
    try {
        const response = yield call(fetchBot,action.bot_id);
        const data = response.data;
        const operators = response.data.operators
        delete response.data.operators
        
        console.log("the fetched data is" + data.agentId);
        console.log(data)
        localStorage.setItem('agent_id', data.agentId); 
        // dispatch a success action to the store with the new dog
        data.webchat_settings = JSON.parse(data.webchat_settings)
        if (data.webchat_widget !== "") {
            data.webchat_widget = JSON.parse(data.webchat_widget);
            console.log(data.webchat_widget + "why here ");
        }
        else {
            data.webchat_widget = {};
        }
        // if (data.webchat_widget !== '') {
        //     data.webchat_widget = JSON.parse(data.webchat_widget);
        //     console.log(data.webchat_widget + "why here ");
        // }
        // else {
        //     data.webchat_widget = {};
        // }
        if(!data.error){
            yield put(callSuccess("BOT_CALL_SUCCESS", data));
            yield put(callSuccess("OPERATORS_CALL_SUCCESS", operators));
        }
        else
            yield put(callFailure("BOT_CALL_FAILURE", true));
    } catch (error) {
        console.log(error);
        yield put(callFailure("BOT_CALL_FAILURE", error));
    }
}

