const initialState = {
  fetching: false,
  chats: [],
  extraChats : [],
  loading: false,
  extraChatsCount:0,
  dtime:null,
  feedBack:true,
  inputDisabled: false,
  inputEnabled: true,
  dtPicker: false,
  selectList: [],
  current_page: null,
  parameters: null,
  contexts: null
};
const message = (state = initialState, action) => {
  let oldChats = state.chats
  switch (action.type) {
    case 'ADD_MESSAGE':
      return { ...state,
        chats: oldChats.concat([{
          message: action.message,
          author: action.author,
          session: action.session,
          id: action.id 
        }]),
        loading: false,
        errors: action.errors,
        feedBack: state.feedBack,
        inputEnabled: true,
        inputDisabled: false,
      };
    case 'ADD_EXTRA_MESSAGE':
      let lastIndex = oldChats.length-1
      let extraChats = [];
      if(undefined !== state.chats[lastIndex].extraChats)
        extraChats = state.chats[lastIndex].extraChats
      if(action.extra_message !=="")
        state.chats[lastIndex].extraChats = extraChats.concat([{
          message:action.extra_message,
        }])
        state.chats[lastIndex].extraChatsCount=action.extraChatsCount
        state.chats[lastIndex].dtime=action.dtime
      return { ...state,
        chats: oldChats,
        loading: false,
        errors:action.errors,
      };
    case "CHAT_VALIDATION_ERROR":
      return { ...state,
        loading: false,
        errors:action.errors,
      };
    case 'MESSAGE_RECEIVED':
      return { ...state,
        chats: oldChats.concat([{
          message:action.message,
          author: action.author,
          session: action.session,
          extraChatsCount:action.extraChatsCount,
          id:action.id,
          dtime:action.dtime 
        }]),
        loading: false,
        errors:action.errors,
        feedBack: action.feedBack,
        current_page: action.current_page,
        parameters: action.parameters,
        contexts: action.contexts
      };
      
    case  'MESSAGE_WILL_BE_RECEIVED':
      return { ...state,
        loading: true,
      };
    
    case 'DISABLEUSERINPUT':
      return { ...state,
        inputDisabled: true,
        inputEnabled: false,
      };
    
    case 'ENABLEUSERINPUT':
      return { ...state,
        inputEnabled: true,
        inputDisabled: false,
      };
    case 'DISABLECAL':
      return { ...state,
        dtPicker: false,
      };
    
    case 'ENABLECAL':
      return { ...state,
        dtPicker: true,
      };
    
    case 'DISABLEMULSEL':
      return { ...state,
        selectList: [],
      };
    
    case 'ENABLEMULSEL':
      return { ...state,
        selectList: [...state.selectList, action.mulItem],
      };
    default:
      return state
  }
}
  
  export default message