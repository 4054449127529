const primaryBubbles  = (data) => {
    let priStyle = {
        color: '#787878',
        backgroundColor: '#dde3eb',
        borderColor: '#dde3eb',
    };
    if (undefined !== data.color && data.color !== '') priStyle.color = `#${data.color}`;
    if (undefined !== data.bg && data.bg !== '' )priStyle.backgroundColor = `#${data.bg}`;
    if (undefined !== data.bg && data.bg !== '' )priStyle.borderColor = `#${data.bg}`;
    
    return priStyle;
};

const secBubbles  = (data) => {
    let secStyle = {
        color: '#787878',
        backgroundColor: '#dde3eb',
        borderColor: '#dde3eb',
    };
    if (undefined !== data.secColor && data.secColor !== '') secStyle.color = `#${data.secColor}`;
    if (undefined !== data.secBg && data.secBg !== '' ) secStyle.backgroundColor = `#${data.secBg}`;
    if (undefined !== data.secBg && data.secBg !== '' ) secStyle.borderColor = `#${data.secBg}`;
    
    return secStyle;
};

export {primaryBubbles, secBubbles};