import React, { Component } from "react"
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

//import "./App.css"
import MessagesList from "./containers/MessagesList"
import { AddMessage } from "./containers/AddMessage"
import { ErrorMessage } from "./containers/ErrorMessage"
import {ApplicationError} from "./errors/AppErrors"
import {initCall} from "./actions/ApiCalls"
import './App2.css';
import './App.css';

class App extends Component {
  
  render() {
    let optionClassName = 'chat-box';
    if(this.props.bot.cssTheme === 'option2') {
      optionClassName = 'chat-box chat-box-optional';
    }

    if(this.props.bot.error){
      return(<div  className={optionClassName}>
        <ErrorMessage />
      </div>)
    }
    else{
      if(this.props.bot.fetching){
        return  (
          <div  className={optionClassName}>
            Please wait while we look for agent.....
          </div>
        )
      }
      return (
        <div  className={optionClassName}>
          <MessagesList />
          <AddMessage />
        </div>
      )
    }
  }
}

App.propTypes = {
  bot:PropTypes.shape({
    fetching: PropTypes.bool,
    data: PropTypes.object,
    error: PropTypes.bool,
  })
}

const mapDispatchToProps = dispatch => ({
  dispatch: (subType,bot_id) => {
    setTimeout(function(){
      dispatch(initCall(subType,bot_id))
    },1000);
  }   
})

//export default App

export default App = connect(state => ({
  bot: state.bot
}), mapDispatchToProps)(App)