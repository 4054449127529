import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import { createStore, applyMiddleware } from 'redux'
import createSagaMiddleware from 'redux-saga'
import { createLogger } from 'redux-logger';
import queryString from 'query-string';

import './index.css'
import App from './App'
import * as serviceWorker from './serviceWorker';
import reducers from './reducers'
import handleNewMessage from './sagas'
import setupSocket from './sockets'

//import formValidationMiddleware from './middlewares/validationMiddleware'

const sagaMiddleware = createSagaMiddleware();
const loggerMiddleware = createLogger();
const dateItem = localStorage.getItem('timeStamp');
const {bot_id} = queryString.parse(window.location.search);
let compareDate = null ;
let currDate =  new Date();
if (dateItem != null) {
  let date2 = new Date(parseInt(dateItem));
  console.log(date2);
  compareDate = date2.getTime() + 1000 * 60 * 60 * 24;
}

if (dateItem != null) {
  if (currDate > compareDate) {
    localStorage.setItem('timeStamp', Date.now());
    localStorage.setItem(`reduxState_${bot_id}`, '{}');
  }
}

let persistedState = localStorage.getItem(`reduxState_${bot_id}`) 
                       ? JSON.parse(localStorage.getItem(`reduxState_${bot_id}`))
                       : {};

const store = createStore(
  reducers,
  persistedState,
  applyMiddleware(
    //formValidationMiddleware,
    loggerMiddleware,
    sagaMiddleware
  )
);
store.subscribe(()=>{
  if (dateItem == null) {
   localStorage.setItem('timeStamp', Date.now());
  }
  const storeState = store.getState();
  localStorage.setItem(`reduxState_${bot_id}`, JSON.stringify(storeState));
})

let socket = setupSocket(store.dispatch)
/*let interval = null;
socket.onclose = function(){
  console.log("socket on close event")
  interval = setInterval(function(){
    socket = setupSocket(store.dispatch)
    console.log(socket.readyState)
    if(socket.readyState === socket.OPEN)
      clearInterval(interval);
  }, 5000);
};*/  
sagaMiddleware.run(handleNewMessage, { socket})

ReactDOM.render(
  <Provider store={store}>
    <App />
  </Provider>,
  document.getElementById('root')
)
serviceWorker.unregister()
/*
import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import * as serviceWorker from './serviceWorker';

ReactDOM.render(<App />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();*/
