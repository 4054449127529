function formatAMPM(date) {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  const sec = date.getSeconds();
  const ampm = hours >= 12 ? 'pm' : 'am';
  hours = hours % 12;
  hours = hours ? hours : 12; // the hour '0' should be '12'
  minutes = minutes < 10 ? `0${minutes}` : minutes;
  const strTime = addZero(hours) + ':' + minutes + ':'    + addZero(sec)+' ' + ampm;
  return strTime;
}
function addZero(i) {
  if (i < 10) {
    i = `0${i}`;
  }
  return i;
}

let formatDate = (date, option = 'datetime', format='Y-m-d') => {
  var d = new Date(date);
    d.setHours(d.getHours()+2);  // added hours for EST adjustment
  var month = '' + addZero(d.getMonth() + 1,2),
    day = '' + addZero(d.getDate(),2),
    year = d.getFullYear(),
    hours = addZero(d.getHours(),10),
    minutes = addZero(d.getMinutes(),10),
    seconds = addZero(d.getSeconds(),10);
  let dateSt = '';
  if (format=='Y-m-d') {
    dateSt = [year, month, day].join('-');
  } else {
    if (format=='m/d/y') dateSt = [month, day, year].join('/');
  }
  
  let retDate = `${dateSt}`;
  if (option == 'datetime') {
    let time = [hours, minutes, seconds].join(':');
    retDate = `${dateSt} ${time}`;
  }
  return retDate;
}

export {formatDate, formatAMPM};