import queryString from 'query-string';

const {option} = queryString.parse(window.location.search);
console.log('the option is'+option)



const initialState = {
    fetching: false,
    data: null,
    error: false,
    cssTheme: 'default'
};

if (undefined !== option)
    initialState.cssTheme = 'option2';

const bot = (state = initialState, action) => {
    switch (action.subType) {
        case 'BOT_CALL_REQUEST':
            return { ...state, fetching: true, data: null, error: false };
        case 'BOT_CALL_SUCCESS':
            return { ...state, fetching: false, data: action.data, error:false };
        case 'BOT_CALL_FAILURE':
            return { ...state, fetching: false, data: null, error: true};
        default:
            return state
    }
  }
  
export default bot