import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Message from './Message';
import './messagelist2.css';
import './messagelist.css';
import { primaryBubbles, secBubbles } from "../utils/customBubbles"
/* const MessagesList = ({ messages }) => (
  //console.log(messages)
    <ul className="chat-list slimscroll p-t-30">
    {messages.map(message => (
        (undefined !== message.errors)?
        "": <Message
        key={message.id}
        {...message}
        />
    ))}
    </ul>
) */
let renderedWithStore = false;
class MessagesList extends Component {
  constructor(props) {
    super(props);
    this.handleClick = this.handleClick.bind(this);
    this.index = 0;
  }

  componentDidMount() {
    if (renderedWithStore) {
      const { dispatch } = this.props;
      dispatch();
      this.scrollToContent = this.scrollToContent.bind(this);
    }
    renderedWithStore = true;
  }

  componentDidUpdate() {
    if (renderedWithStore) this.scrollToContent();
    // console.log("the index is"+this.index)
  }

  /*handleClick(e) {
    // console.log(e.target.nodeName)
    const { dispatchAddMessage } = this.props;
    if (e.target.nodeName === 'BUTTON') dispatchAddMessage(e.target.innerHTML, 'Me');
  }*/
  handleClick(e){
    console.log(e);
    console.log(e.target);
    console.log(e.target.textContent);
    if(e.target.nodeName === "BUTTON"){
      if(undefined !== e.target.attributes[1]){
        var locationPattern = /location_botid/g;
        console.log(e.target.attributes);
        var locMatch = e.target.attributes[1].name.match(locationPattern)
        if(undefined !== e.target.attributes[2] && undefined !== e.target.attributes[2].name)
          locMatch = e.target.attributes[2].name.match(locationPattern)
        console.log(e.target.attributes[1].name);
        if(null !== locMatch){
          var numberPattern = /\d+/g;
          var m = e.target.attributes[1].name.match( numberPattern )
          if(undefined !== e.target.attributes[2] && undefined !== e.target.attributes[2].name)
            m = e.target.attributes[2].name.match( numberPattern )
          var locId = parseInt(m);
          const urlSearchParams = new URLSearchParams(window.location.search);
          const params = Object.fromEntries(urlSearchParams.entries());
          let addedParams = `bot_id=${locId}`;
          if (undefined !== params.option) {
            Object.entries(params).forEach(([key, value]) => {
              if (key !== 'bot_id') addedParams += `&${key}=${value}`;
            });
          }
          window.location = 'https://webchat.newpatientassistantamplify360.com/?'+addedParams;
        }else{
          var mulSelPattern = /mul_sel/g;
          console.log(e.target.attributes);
          if (undefined !== e.target.attributes[2]) {
            var mulSelMatch = e.target.attributes[2].name.match(mulSelPattern);
            console.log(e.target.textContent);
            console.log(mulSelMatch);
            if (null !== mulSelMatch) {
              this.props.dispatchAddSelList(e.target.textContent);
            } 
          } else {
            if (e.target.textContent === 'Disconnect') {
              setInterval(function(){
                localStorage.clear()},
                2000,
              );
            }
            this.props.dispatchAddMessage(e.target.innerHTML,"Me")
          }
          
        }
      }else{
        if (e.target.textContent === 'Disconnect') {
          localStorage.clear();
        }
        this.props.dispatchAddMessage(e.target.innerHTML,"Me")
      }
    }
  }
  scrollToContent() {
    // if(this.index >=1 )
    // this.messagesEnd.scrollIntoView({ behavior: "smooth" });
    this.messagesEnd.parentNode.scrollTop = this.messagesEnd.offsetTop;
  }

  render() {
    const { message, bot, operators } = this.props;
    const urlData = new URL(document.location);
    console.log("the url data is");
    console.log(urlData);
    const customizeData = {
      bg: urlData.searchParams.get("bg"),
      color: urlData.searchParams.get("clr"),
      secBg: urlData.searchParams.get("secBg"),
      secColor: urlData.searchParams.get("secColor"),
    };
    const priStyles = primaryBubbles(customizeData);
    const secStyles = secBubbles(customizeData);
    const paramOption = urlData.searchParams.get("option");
    if (message.loading) {
      this.index = message.chats.length - 1;
      let imgSrc = bot.data.webchat_settings.bot_avatar;
      if (
        bot.data.webchat_widget !== null 
        && undefined !== bot.data.webchat_widget.bot_avatar
        && paramOption === "2"
      ) imgSrc = bot.data.webchat_widget.bot_avatar;
      if (undefined !== message.chats[this.index - 1]) {
        const { author } = message.chats[this.index - 1];
        if (! isNaN(author)) {
          let operator = operators.data.find(function (element) {
            return element.id == author;
          });
          console.log(operator);
          imgSrc = operator.profile_img_url;
        }
      }
      return (
        <ul className="chat-list slimscroll">
           { message.chats.map((chat, index) => (
             (undefined !== chat.errors)?
             "": <Message 
                        key = {chat.id} 
                        message = { chat } 
                        origMessage = {this.props.message}
                        extraChats = { message.extraChats }
                        extraChatsCount = { chat.extraChatsCount }
                        dispatchAddMessage = { this.props.dispatchAddMessage }
                        dispatchDisableInput = { this.props.dispatchDisableInput }
                        dispatchEnableInput = { this.props.dispatchEnableInput }
                        handleClick = { this.handleClick }
                        bot = { bot }
                        operators = { operators }
                        dtime = { chat.dtime }
                        priStyles= { priStyles }
                        secStyles = { secStyles }
                      />
            ))}
           <li className="even">
              <div className="chat-image"> 
                {(undefined !== message.chats[this.index] && message.chats[this.index].extraChatsCount > 0)?"":<img alt="male" src={imgSrc}/> }
              </div>
              <div className="chat-body loading-bar">
                  <div className="chat-text">
                      <p className="loading-dots"> <h1 className="dot one">.</h1><h1 className="dot two">.</h1><h1 className="dot three">.</h1></p>
                  </div>
              </div>
           </li>
           <div style={{ float:"left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
          </div>
        </ul>
      );
    } else {
      let imgSrc2  = ""
      if (message.chats.length > 0){
        this.index = message.chats.length -1;
        let imgSrc2  = bot.data.webchat_settings.bot_avatar
        if (bot.data.webchat_widget !== null && undefined !== bot.data.webchat_widget.bot_avatar) 
          imgSrc2 = bot.data.webchat_widget.bot_avatar;
        if (undefined !== message.chats[this.index]) {
          let msg = message.chats[this.index]
          if (!isNaN(msg.author)) {
            let operator = msg.author
            let oAvatar = operators.data.find(function(element) {
                            return element.id == operator;
                          });
            imgSrc2  = oAvatar.profile_img_url
            console.log("test oavatar")
            console.log(imgSrc2);
          }
        }
      }
      return(
        <ul className="chat-list slimscroll">
         { message.chats.map(chat => (
             (undefined !== chat.errors)?
             "": <Message 
             key = { chat.id } 
             message = { chat } 
             extraChats = { message.extraChats }
             origMessage = {this.props.message}
             dispatchAddMessage = { this.props.dispatchAddMessage }
             dispatchDisableInput = { this.props.dispatchDisableInput }
             dispatchEnableInput = { this.props.dispatchEnableInput }
             handleClick = { this.handleClick }
             bot = { bot }
             extraChatsCount = { chat.extraChatsCount }
             dtime = { chat.dtime }
             operators = { operators }
             priStyles= { priStyles }
             secStyles = { secStyles }
           />
         ))}
         <div style={{ float:"left", clear: "both" }}
              ref={(el) => { this.messagesEnd = el; }}>
          </div>
         </ul>
       )
    }
  }
}
MessagesList.propTypes = {
  message: PropTypes.shape({
    chats: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number,
        message: PropTypes.string,
        extraChats: PropTypes.arrayOf(
          PropTypes.shape({
            message: PropTypes.string
          })
        ),
        extraChatsCount: PropTypes.number,
        author: PropTypes.string,
        dtime: PropTypes.string,
      })
    ),
    loading: PropTypes.bool,
    errors: PropTypes.bool,
  }),
  bot:PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool,
    fetching: PropTypes.bool
  }),
  operators: PropTypes.shape({
    data: PropTypes.object,
    error: PropTypes.bool,
    fetching: PropTypes.bool
  }),
  dispatch: PropTypes.func.isRequired,
  dispatchAddMessage: PropTypes.func.isRequired,
};
export default MessagesList;
