import React,{ useState } from 'react';
import PropTypes from 'prop-types';
import DatePicker from "react-datepicker";
import {formatDate} from "../utils/formatDate"
import "react-datepicker/dist/react-datepicker.css";

const AddMessage = (props) => {
  let input,button
  const [startDate, setStartDate] = useState();
  
  let handleKeyPress = (e) =>{
    if (props.bot.cssTheme !== 'option2') {
      if(input.value.length === 0)
        button.disabled = true;
      if(input.value.length > 0)
        button.removeAttribute('disabled') ;
      }
    if (e.key === 'Enter') {
      submitForm(e);
    }
  };
  let submitForm = (e)=>{
    console.log("before submit form")
    console.log(props.message);
    let feedback = props.message.feedBack;
    if(undefined === props.message.feedBack) feedback = true;
    console.log("the feed back is"+props.message.feedBack )
    console.log("the feed back is"+feedback )
    if (undefined !== input.value) {
      if(input.value.length > 0){
        props.dispatch(input.value, 'Me',feedback)
        input.value = ''
        button.disabled = true;  
      }
    } else {
      //console.log("dateSelected is "+ startDate);
      let dtSel = formatDate(startDate,'date','m/d/y');
      props.dispatch(dtSel, 'Me',feedback)
      setStartDate('');
      button.disabled = true;  
    }
    
  };
  let option2 = () => {
    return (
      <div className="row send-chat-box">
          <div className="col-sm-12">
            <div class="field-with-icon">
              <input onKeyUp={handleKeyPress}
                  disabled = { props.message.inputDisabled}
                  type="text"
                  ref={(node) => {
                  input = node
                }} className="formField" placeholder="Type your message"/>
                <a ref={(node) => {
                    button = node
                    if (input !== null) input.value = props.message.selectList.toString()
                  }} onClick={submitForm}  href="#" class="sendBtn"> <img src="https://webchat.newpatientassistantamplify360.com/images/message-box-arrow.png" alt="send"/>  </a>
            </div>
          </div>
      </div>
    )
  };
  let option1 = () => {
    return (
      <div className="row send-chat-box">
          <div className="col-sm-12">
              <input onKeyUp={handleKeyPress}
                  disabled = { props.message.inputDisabled}
                  type="text"
                  ref={(node) => {
                  input = node
                }} 
                className="form-control" 
                placeholder="Type your message"/>
              <div className="custom-send">
                  <button ref={(node) => {
                  button = node
                  if (input !== null) input.value = props.message.selectList.toString()
                  if (button !== null && props.message.inputDisabled) 
                    button.disabled = true;
                  else if (button !== null && !props.message.inputDisabled)
                    button.removeAttribute('disabled')//button.disabled = "false";
                }} onClick={submitForm} 
                className="btn btn-primary" 
                type="button"
                
                >SEND</button>
              </div>
  
          </div>
      </div>
    )
  };
  let simulateClick = (e) => {
     if (null !== e && startDate==null)
      setTimeout(function(){e.querySelector('#dt_picker1').click(); }, 3000);
  }
  let dtPicker2 = () => {
    return (
      <div className="row send-chat-box" ref={simulateClick}>
          <div className="col-sm-12">
            <div class="field-with-icon">
            <DatePicker
              disabled = { props.message.inputDisabled}
              selected={startDate} 
              onChange={date => setStartDate(date)}
              ref={(node) => {
                  input = startDate
                }}
              className="form-control" placeholder="Type your message"
              id="dt_picker1"
            />
              <a ref={(node) => {
                      button = node
                    }} onClick={submitForm}  href="#" class="sendBtn"> <img src="https://webchat.newpatientassistantamplify360.com/images/message-box-arrow.png" alt="send"/>  </a>
            </div>
          </div>
      </div>
      
    )
  };
  let dtPicker1 = () =>{
    //setTimeout(function(){ input.click(); }, 2500);
    return (
      <div className="row send-chat-box">
        <div className="col-sm-12" ref={simulateClick}>
            <DatePicker
              disabled = { props.message.inputDisabled}
              selected={startDate} 
              onChange={date => setStartDate(date)}
              ref={(node) => {
                  input = node
                }}
              className="form-control" placeholder="Type your message"
              id="dt_picker1"
            />
            <div className="custom-send">
                  <button ref={(node) => {
                  button = node
                }} onClick={submitForm} 
                className="btn btn-primary" 
                type="button"
                >SEND</button>
              </div>
  
          </div>
      </div>
    )
  };
  if(undefined !==props.bot)
  console.log(props.bot)
  if (props.bot.cssTheme === 'option2') {
    if (props.message.dtPicker === true) return dtPicker2();
    else return option2();
  } else {
    if (props.message.dtPicker === true) return dtPicker1();
    else return option1();
  }
}

AddMessage.propTypes = {
  dispatch: PropTypes.func.isRequired,
  messages: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      message: PropTypes.string,
      author: PropTypes.string,
    })
  ),
  bot:PropTypes.object
}

export default AddMessage