import * as types from '../constants/ActionTypes'
import {formatAMPM} from "../utils/formatDate"
import { 
  messageReceived,
  messageWillbeReceived,
  additionalMessageRecieved,
  disableUserInput,
  enableInput,
  enableCal,
  disableCal  
} from '../actions'
import {parseMessage} from "../utils/parser"
import {initCall} from "../actions/ApiCalls"
import queryString from 'query-string'

let finalMs = 0;
let completedMs = 0;
let loadingCounter = 1;
const generateKey = (se) => {
  return `${ se }_${ new Date().getTime() }`;
}

const widget_incoming = document.getElementById("widget_incoming2"); 
        

const searchandDelay=(data,dispatch)=>{
  let cDate = new Date();
  let finalDate = formatAMPM(cDate)
  let message = data.message
  let author = data.author
  let feedBack = data.feedBack
  let matches = message.match(/::(.+?)0::/g);
  let start = 0
  var end = 0;
  var extract = 0
  var res = ""
  finalMs = 0;
  completedMs = 0;
  loadingCounter = 1;
  if(null !== matches){
    for(let i = 0; i< matches.length; i++){
      end = message.indexOf(matches[i]);
      if(res!==""){
        if("::next-"+res+"::" === matches[i])
          start=message.indexOf("::next-"+res+"::",end+13);
        else
          start=message.indexOf("::next-"+res+"::");
      }
      extract = message.substring(start, end);
      extract = extract.replace("::next-"+res+"::","");
      if(res === ""){
        dispatch(
          messageReceived(
            extract,
            author,
            matches.length,
            finalDate,
            feedBack,
            data.current_page,
            data.parameters,
            data.contexts
          )
        );
      }
      else{
        if(extract!=="")
          delay(res,extract,author,dispatch,matches.length,finalDate,feedBack)
      }
      res = matches[i].replace(/\D/g, "");
    }
    //for left over message
    if(start <= end )
      start=end;
    end = message.length
    extract = message.substring(start, end);
    extract = extract.replace("::next-"+res+"::","");
    if(extract!=="")
      delay(res,extract,author,dispatch, matches.length,finalDate,feedBack)
  } else {
    const message = parseMessage(data.message);
    let cmessage = data.message.replace("::datepicker::","");
    console.log(message)
    if (message.disableInput) dispatch(disableUserInput()) ;
    else dispatch(enableInput())
    if (message.enableCal) dispatch(enableCal()) ;
    else dispatch(disableCal())
    dispatch(
      messageReceived(
        cmessage,
        data.author,
        0,
        finalDate,
        feedBack,
        data.current_page,
        data.parameters,
        data.contexts
      )
    );
  }
}


const delay=(ms,text,author,dispatch,extraChatsCount,finalDate,feedBack)=>{
  finalMs=parseInt(ms)+parseInt(finalMs) 
  if(loadingCounter <= 1)//if not showing loading status
    dispatch(messageWillbeReceived(feedBack));
  loadingCounter ++;
  setTimeout(function(){ 
    completedMs = parseInt(ms)+parseInt(completedMs) 
    //console.log(text)
    const message = parseMessage(text);
    let cmessage = text.replace("::datepicker::","");
    console.log(message)
    if (message.disableInput) dispatch(disableUserInput()) ;
    else dispatch(enableInput())
    if (message.enableCal) dispatch(enableCal()) ;
    else dispatch(disableCal())
    dispatch(additionalMessageRecieved(cmessage,extraChatsCount,finalDate,feedBack))
    if(completedMs !== finalMs)
      dispatch(messageWillbeReceived(feedBack));
  }, finalMs);
}

const setupSocket = (dispatch) => {
  const storedKey = localStorage.getItem("key");
  let key = "";
  if(storedKey){
    key = storedKey;
  }else{
    key = generateKey("sess")
    localStorage.setItem('key', key); 
  }
  const {bot_id} = queryString.parse(window.location.search) 
  //const socket = new WebSocket('ws://'+types.SERVER_ADDRESS+':8989?id='+key+"&bot_id="+bot_id)
  const socket = new WebSocket('wss://'+types.SERVER_ADDRESS+':8988?id='+key+"&bot_id="+bot_id)

  socket.onopen = () => {
    console.log("socket loaded should send request here");
    const {bot_id} = queryString.parse(window.location.search) 
    //let bot_id = bot_id;
    dispatch(initCall("BOT_CALL_REQUEST",bot_id));
  }
  socket.onmessage = (event) => {
    const data = JSON.parse(event.data)
    switch (data.type) {
      case types.ADD_MESSAGE:
        if(!isNaN(data.author)){
          widget_incoming.play();
        }
        searchandDelay(data,dispatch)
        break
      default:
        break
    }
  }

  return socket
}

export default setupSocket