import React from "react"
import PropTypes from "prop-types"
import {parseMessage,createMarkup} from "../utils/parser"

const ExtraMessage = (props) => {
  let message = props.extraMessage.message
  let finalDate = props.dtime;
  let customStyles =props.style;
  if(undefined !== message ){  
    let messages = parseMessage(message)
    let ShowDate = ``;
    if(props.currentIndex === (props.extraChatsCount-1)){
        ShowDate = `${finalDate}`;
    }
    
    if(messages.html === "") {
      if(props.bot.cssTheme === 'option2') {
        console.log("date-div 1");
        console.log(customStyles);
        return(
          <div class="date-div">
            <div className="chat-text" style={customStyles}>
              <p> {message} </p>
            </div>
            <span>{ShowDate}</span>
          </div>
        )
      } else {
        console.log("date-div 2");
        return(
          <div className="chat-text" style={customStyles}>
              <p> {message} </p> <b>{ShowDate}</b>
          </div>
        )
      } 
    } else {
      if(props.bot.cssTheme === 'option2') {
        return(
          <div class="date-div">
            <div className="chat-text" style={customStyles}>
              <p> {messages.text} </p>
            </div>
            <br/>
            <span>{ShowDate}</span>
            <div className="button-include"> 
              <div onClick={props.handleClick} className="quick_reply" dangerouslySetInnerHTML={createMarkup(messages.html, customStyles)} />
            </div>
          </div>
        )
      } else {
        return( 
          <div className="button-include"> 
            <div className="chat-text">  
                <p> {messages.text} </p> <b>{ShowDate}</b>
            </div>
            <div onClick={props.handleClick} className="quick_reply" dangerouslySetInnerHTML={createMarkup(messages.html)} />
          </div>
        )
      }        
    }
  } else return "";
}

ExtraMessage.propTypes = {
  message: PropTypes.string,
  author: PropTypes.string,
  props:PropTypes.object
}

export default ExtraMessage

//15 hours to add